
import Component from "vue-class-component";
import Vue from "vue";
import { Market, PostAppStrategyrungroupCreateRequest, StrategyRunGroup } from "next-trader-api";
import { strategyRunGroupApi } from "@/plugins/api";
import { map } from "rxjs/operators";
import { mdiAutoFix, mdiChartBox, mdiChevronDown, mdiChevronUp, mdiInformation, mdiPlay } from "@mdi/js";

@Component
export default class BackTest extends Vue {
  strategyRunGroups: Array<StrategyRunGroup> = [];

  snackbar = false;
  snackbarText = "";

  icons = {
    mdiPlay: mdiPlay,
    mdiAutoFix: mdiAutoFix,
    mdiChartBox: mdiChartBox,
    mdiChevronUp: mdiChevronUp,
    mdiChevronDown: mdiChevronDown,
    mdiInformation: mdiInformation,
  };

  getStrategyRunGroupGet$() {
    return strategyRunGroupApi.getAppStrategyrungroupIndex().pipe(
      map((next: Array<StrategyRunGroup>) => {
        this.strategyRunGroups = next;
        return next;
      })
    );
  }

  runStrategy() {
    this.snackbarText = "Strategy group is being created...";
    this.snackbar = true;
    const request: PostAppStrategyrungroupCreateRequest = {
      strategyRunGroupCreateRequest: {
        exchangeInitialBalances: [{ exchangeId: 0, currencyId: 0, value: 100000 }],
        marketIds: [6, 7],
        from: "2019-01-01",
        to: "2020-08-13",
        isBackTesting: true,
        strategyId: 1,
        saveOnlySummary: true,
      },
    };
    strategyRunGroupApi.postAppStrategyrungroupCreate(request).subscribe(() => {
      this.snackbarText = "Strategy group is done!";
      this.snackbar = true;
      this.getStrategyRunGroupGet$().subscribe();
    });
  }

  created() {
    this.getStrategyRunGroupGet$().subscribe();
  }

  headers: Array<{ text: string; value: string }> = [
    { text: "Started at", value: "startedAt" },
    { text: "Markets", value: "markets" },
    { text: "Net profit", value: "netProfit" },
    { text: "Strategy", value: "strategy.name" },
    { text: "2018", value: "profit2018" },
    { text: "2019", value: "profit2019" },
    { text: "2020", value: "profit2020" },
  ];

  getMarketNames(markets: Array<Market>) {
    return markets.map((market) => market.name).reduce((result, name) => result + ", " + name);
  }

  // getIconColor(item: any, periodType: string, periodName: string) {
  //   return item.summariesIndexed[periodType][periodName].netProfitPercent > 0
  //     ? "green"
  //     : "red";
  // }
  //
  // getNetProfitPercent(item: any, periodType: string, periodName: string) {
  //   return item.summariesIndexed[periodType][periodName].netProfitPercent;
  // }
}
